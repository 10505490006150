.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar {
  background-color: #fff;
}
.navbar-collapse ul li a {
  color: rgba(0, 0, 0, 0.4);
  color: color(display-p3 0 0 0 / 0.4);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.navbar-collapse button {
  background: #8cc63e;
  box-shadow: 5px 4px 7px 0px color(display-p3 0 0 0 / 0.25);
}
.navbar-collapse ul li {
  padding: 0px 20px;
}
.footer {
  margin-top: 100px;
}
.footer-list ul li a {
  color: #999999 !important;
  font-size: 18px;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.footer-list li a:hover {
  transition: all 0.2s;
  color: #8cc63e !important;
}
.green {
  color: #8cc63e !important;
}
.lightBlue {
  color: #55acee;
}
.lightbrown {
  color: #5e5e5e;
}
.text-color {
  color: #7d8b97;
  font-size: 15px;
}
.lightblack {
  color: #5b5b5b;
  font-size: 25px;
}
.light {
  color: #999999;
}
.text {
  font-family: Crimson text;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.32px;
}
.shadowBg {
  height: 480px;
  background: #fff;
  background: color(display-p3 1 1 1);
  box-shadow: 0px -1px 10px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px -1px 10px 2px color(display-p3 0 0 0 / 0.25);
}
.shadowBg1 {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1210;
  background: #fff;
  background: color(display-p3 1 1 1);
  box-shadow: 0px -1px 10px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px -1px 10px 2px color(display-p3 0 0 0 / 0.25);
}
.nav-item .active {
  color: #8cc63e !important;
}
.list-group-item .active {
  color: #8cc63e !important;
}
.hoverdark:hover {
  color: #8cc63e !important;
}
.footer .fw {
  font-weight: 600;
}
.HeroSection1 {
  background-color: #d1e8b2;
  height: 100vh;
  min-height: 603px;
}
.HeroSection2 {
  min-height: 650px;
}
.HeroSection3 {
  min-height: 650px;
}
.HeroSection4 {
  min-height: 650px;
}
.HeroSection1 .heading h2 {
  font-family: Crimson text;
  font-size: 40px;
  color: #4d5642;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.HeroSection1 .heading p {
  font-size: 18px;
  font-weight: 500;
  color: #4d5642;
  letter-spacing: 0.3px;
}
.HeroSection1 button {
  background: #7ac80b;
  background: color(display-p3 0.549 0.7765 0.2431);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px color(display-p3 0 0 0 / 0.25);
}
.HeroSection1 select {
  border: none;
  height: 43px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #fff;
  background: color(display-p3 1 1 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 4px 0px color(display-p3 0 0 0 / 0.15);
}
.HeroSection1 input {
  outline: none;
  border: none;
  height: 43px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #fff;
  background: color(display-p3 1 1 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 4px 0px color(display-p3 0 0 0 / 0.15);
}
.HeroSection1 h5 {
  color: #4d5642;
}
.HeroSection1 input::placeholder {
  font-weight: 500;
  font-size: 15px;
  color: #999999;
  letter-spacing: 0.45px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.HeroSection1 option {
  font-weight: 500;
  font-size: 15px;
  color: #999999;
  letter-spacing: 0.45px;
}
.HeroSection1 select {
  outline: none;
  font-weight: 500;
  font-size: 15px;
  color: #999999;
  letter-spacing: 0.45px;
}
.HeroSection1 .last-div {
  height: 100px;
  background-color: #a1d161;
}
.HeroSection1 form i {
  position: absolute;
  color: #999999;
  top: 15px;
  right: 15px;
  bottom: 0px;
  font-size: 18px;
}
.HeroSection1 .p-tag {
  font-family: Crimson text;
  font-weight: 700;
  font-size: 27px;
  justify-content: end;
  display: flex;
  line-height: 26.09px;
  color: #3b4d24;
}
.HeroSection1 .p-tag-1 {
  font-weight: 700;
  font-size: 17px;
  line-height: 35.09px;
  color: #3b4d24;
}
.HeroSection1 .last-img {
  margin-top: -60px;
}
.HeroSection1 .main {
  min-height: 270px;
}
.HeroSection1 .input-div {
  margin-bottom: 100px;
}
.HeroSection1 .img {
  width: 75%;
  margin-top: -10px;
  height: 75%;
}
.HeroSection1 .img1 {
  width: 90%;
  margin-top: 40px;
  height: 90%;
}
.HeroSection2 .content-div {
  background-image: url("../src/Images/circles-a\ 1\ \(1\).png");
  background-position: center;
  background-repeat: no-repeat;
}
.HeroSection2 .font {
  font-family: Crimson text;
  font-weight: 600;
  font-size: 40px;
  color: #5d5d5e;
}
.HeroSection2 .content-div p {
  font-weight: 500;
  color: #5d5d5e;
  font-size: 15px;
}
.PricingSection1 {
  background-image: url("../src/Images/Hero\ section.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.PricingSection2 .card-item {
  /* width: 387.12px; */
  height: 560px;
  flex-shrink: 0;
  border-radius: 26px;
  background: #8cc63e;
  box-shadow: 0px 42px 34px 0px color(display-p3 0.3216 0.2627 0.7608 / 0.3);
}
.PricingSection2 ul {
  list-style: none;
  padding: 0;
}

.PricingSection2 li {
  position: relative;
  font-size: 15px;
  padding-left: 25px;
  margin-bottom: 8px;
}

.PricingSection2 li::before {
  content: "\2713";
  color: rgb(255, 255, 255);
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.PricingSection2 .card-item h2 {
  font-family: Crimson Text;
  font-weight: 500;
  font-size: 40px;
  color: white;
}
.PricingSection2 .card-item .Main-btn {
  width: 307.575px;
  border: none;
  height: 54.938px;
  flex-shrink: 0;
  border-radius: 24px;
  background: color(display-p3 0 0 0 / 0.63);
  color: color(display-p3 0.549 0.7765 0.2431);
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.21px;
}
.PricingSection2 .img-div img {
  overflow: hidden;
}
.PricingSection2 .card-item .most-btn {
  width: 160.416px;
  height: 32.963px;
  border: none;
  flex-shrink: 0;
  color: #f5f5f5;
  color: color(display-p3 0.9608 0.9608 0.9608);
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.833px;
  border-radius: 13.5px;
  background: rgba(0, 0, 0, 0.4);
  background: color(display-p3 0 0 0 / 0.4);
}
.PricingSection3 {
  margin-top: 180px;
}
.PricingSection3 h1 {
  font-family: Crimson text;
  color: #5e5e5e;
}
.PricingSection3 .about-list {
  height: auto;
  background-color: #d1e8b2;
  width: 100%;
}
.PricingSection3 .padding {
  padding: 0px 120px;
}
.PricingSection3 .about-list {
  padding: 50px;
}
.PricingSection3 .about-list h4 {
  color: #4d5642;
}
.PricingSection3 .about-list i {
  color: #4d5642;
}
.ToolsSection1 {
  background-image: url("../src/Images/Hero\ section.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ToolsSection1 input {
  border: 2px solid #b5b5b5;
  box-shadow: none;
  height: 55px;
  border-radius: 12px;
}
.ToolsSection1 .input-div {
  margin-bottom: 0px;
}
.btn-div button {
  width: 175px;
  color: #999999;
  font-weight: 600;
  background-color: #fff;
  height: 53px;
  border: 2px solid #8cc63e;
  flex-shrink: 0;
}
.btn-div button:hover {
  color: white;
  transition: all 0.7s;
  background-color: #8cc63e;
  box-shadow: 3px 3px 10px #999999;
}

.input-div i {
  color: #999999;
}
.ToolsSection2 {
  margin-top: 100px;
}
.ToolsSection2 .box {
  width: 392px;
  height: auto;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border: 1px solid color(display-p3 0 0 0 / 0.25);
  background: #e6e6e6;
  background: color(display-p3 0.902 0.902 0.902);
}
.ToolsSection2 .box-demo {
  /* width: 313px; */
  height: 45px;
  flex-shrink: 0;
  color: #999999;
  text-align: left;
  border-radius: 6px;
  background: #fff;
  background: color(display-p3 1 1 1);
}
.ToolsSection2 .box-demo {
  border: none;
}
.BlogSection1 input {
  height: 60px !important;
  outline: none;
  border: none;
  height: 43px;
  flex-shrink: 0;
  border-radius: 12px !important;
  border-radius: 6px;
  background: #fff;
  border: 2px solid #999999;
  background: color(display-p3 1 1 1);
  box-shadow: none;
}
.BlogSection1 input::placeholder {
  font-size: 17px;
}
.BlogSection1 .translate-middle-y {
  color: #999999;
}
.BlogSection2 {
  width: 100%;
  margin-top: 100px;
  min-height: 670px;
}
.BlogSection2 h2 {
  color: #5e5e5e;
  font-family: Crimson;
  font-weight: 600;
  line-height: 47.99px;
}
.BlogSection2 .paragraph {
  height: 350px;
  padding: 0px 150px;
}
.BlogSection2 .paragraph p {
  font-weight: 500;
}
.BlogSection2 .icon-div {
  padding: 0px 150px;
}
.BlogSection2 .icon-div h6 {
  font-weight: 700;
}
/* .SummarySection .title {
  border: 3px solid #e6e6e6;
  border-radius: 7px;
} */
.SummarySection .image img {
  border-radius: 7px;
}
.SummarySection .title h1 {
  font-family: Crimson text;
}
.SummarySection .title .spanFont {
  font-weight: 500;
  color: #5e5e5e;
}
.SummarySection .title p {
  font-size: 18px;
}
.Summary2 .title {
  background-color: #f0f0f0;
}
.Summary2 .title button.active {
  background-color: #fff;
  color: #000 !important;
  border-bottom: 3px solid #8cc63e;
  transition: background-color 0.3s linear, color 0.3s linear,
    border-bottom 0.3s linear;
}
.Summary2 .title button {
  border: none;
}

.Summary2 .title button.active h3 {
  color: #000;
}
.Summary2 .title h3 {
  color: #5b5b5b;
}
.Timeline .fram {
  height: 100px;
}
.Timeline .fram img {
  height: auto;
  border: 1.2px solid #e0e0e0;
}
.Timeline .fram span {
  font-size: 14px;
  font-weight: 500;
  color: #7d8b97;
  background-color: #e0e0e0;
  padding: 5px 8px 5px 8px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.Timeline {
  overflow: hidden;
}
.Performance h2 {
  color: #5a5a5a;
  font-weight: 500;
}
.Performance p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #5a5a5a;
}
.Performance .box {
  height: 158px;
  max-width: 303px;
  background-color: #e6eedb;
}
.Performance .box div {
  height: 40px;
  background-color: #36a927;
}
.Performance .coler {
  background-color: #fff2dd;
}
.Performance .coler div {
  background-color: #f6ab34;
}
.Performance .coler1 {
  background-color: #fff2dd;
}
.Performance .coler1 div {
  background-color: #e45858;
}
.Performance .box1 {
  width: 303px;
  height: 128px;
  border-radius: 5px;
  border: 2px solid #8cc63e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.Structure button {
  width: 90px;
  height: 34px;
  border-radius: 5px;
  color: #5b5b5b;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #e4e4e4;
}
.Structure .btn {
  width: auto;
  display: flex;
  align-items: center;
}
.Structure h3 {
  margin: 0px;
  font-size: 20px;
  color: #5e5e5e;
}
.Structure h3 a {
  color: #5e5e5e;
}
.Structure .title {
  background-color: #eef2e8;
  border-radius: 12px 12px 0px 0px;
}
.Structure .border-bottom-2 {
  border-bottom: 2px solid #999999;
  overflow: auto;
}
.Structure .score-btn {
  width: 68px;
  height: 38px;
  color: #fff;
  background: #36a927;
  border-radius: 0%;
}
.Structure .col-md-1 .high-color {
  background: #e44d26;
}
.Structure .col-md-1 .med-color {
  background: #ffab00;
}
.Structure .col-md-1 .blank-color {
  background: #bcb9cb;
}
.Waterfall h3 {
  color: #5b5b5b;
}
.Waterfall .border-bottom-2 p {
  font-weight: 500;
  color: #5b5b5b;
}

.Waterfall .table p {
  color: #7d8b97;
}
.SummarySection .refresh-btn {
  background-color: #8cc63e;
  border: none;
  box-shadow: 5px 4px 7px 0px color(display-p3 0 0 0 / 0.25);
  color: white;
}
.Performance .container-box {
  background-color: #f2f6f9;
  margin-bottom: 50px;
  height: auto;
  border-radius: 10px;
}
.Performance .container-box .child-green-box {
  width: 15px;
  height: 15px;
  background: #94b569;
  display: block;
}
.Performance .container-box .child-blue-box {
  width: 15px;
  height: 15px;
  background: #7488ee;
  display: block;
}
.Performance .container-box .child-orange-box {
  width: 15px;
  height: 15px;
  background: #d27e4f;
  display: block;
}
.Performance .container-box .child-red-box {
  width: 15px;
  height: 15px;
  background: #e72323;
  display: block;
}
.Performance .container-box span {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #7d8b97;
  letter-spacing: 1%;
}
.Structure .col-12 .active {
  background: #8cc63e;
  color: #fff;
  border: 2px solid #8cc63e;
  box-shadow: 5px 4px 7px 0px color(display-p3 0 0 0 / 0.25);
  transition: all 0.8s;
}
.Structure .padding {
  background-color: #f2f6f9;
  padding: 40px;
  border-radius: 6px;
}
.Structure .padding .borderLineIn {
  border-radius: 6px;
  border: 2px solid #7d8b97;
}
.Structure .padding .about-list .borderLine {
  border-bottom: 1px solid #7d8b97;
  background-color: #ebedee;
}
.Structure .padding .about-list .borderLine:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.Structure .padding .about-list .drop-down {
  padding: 0px 25px;
  background-color: #f2f6f9;
}
.Structure .padding .about-list .drop-down p {
  font-size: 17px;
  margin-top: 20px;
  font-weight: 500;
  line-height: 25px;
}
.Structure .padding .about-list .drop-down img {
  width: 100px;
}
.Structure-Table .table:last-child {
  margin-bottom: 60px !important;
}
.Structure .padding .about-list .drop-down:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.Structure .padding .about-list i {
  padding: 0px 25px;
  color: #464646;
}
.Structure .padding .about-list .borderLine:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.Structure .padding .about-list .borderLine:last-child {
  border-bottom: none !important;
}
.Structure .padding .about-list .borderLine h4 {
  font-size: 17px;
  padding: 10px 25px;
  color: #464646;
}
.Structure .padding .about-list .borderLine .Line {
  border-bottom: 1px solid #7d8b97;
}
.Waterfall .table .status button {
  width: 60px;
  font-size: 14px;
  height: 25px;
  border-radius: 5px;
  background-color: #8cc63e;
  font-weight: 500;
  color: #fff;
  border: 1px solid #e4e4e4;
}
.Waterfall .table img {
  padding-top: 0px;
  padding-bottom: 0px;
}
.Waterfall .table div {
  cursor: pointer;
}
.Waterfall .heading {
  background-color: #efefef;
  border-left: 2px solid #efefef;
  border-right: 2px solid #efefef;
}
.waterfall-dropdown .dropdown-content {
  border: 2px solid #e4e4e4;
  border-left: none;
  border-right: none;
}
.waterfall-dropdown .dropdown-content .overflow-hidden div {
  font-size: 14px;
}
.waterfall-dropdown .dropdown-content .borderBottom {
  border-bottom: 2px solid#e4e4e4;
}
.waterfall-dropdown .dropdown-content .borderBottom1 {
  border-top: 2px solid #e4e4e4;
  border-bottom: 2px solid #e4e4e4;
}
.waterfall-dropdown .dropdown-box {
  border-left: 2px solid #e4e4e4;
  border-right: 2px solid #e4e4e4;
}

.Waterfall .table .urlPath .url {
  margin: 0;
}

.Waterfall .table .urlPath .full-url {
  display: none;
  white-space: nowrap;
}

.Waterfall .table .urlPath:hover .full-url {
  display: inline;
}

.WaterfallTitle .container.table:hover {
  background-color: #efefef;
  transition: all 0.2s;
}

.container.table {
  white-space: nowrap;
  cursor: pointer;
}

.Waterfall .table .urlPath .url {
  position: relative;
  display: inline-block;
}

.Waterfall .table .urlPath .full-url {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  background-color: #efefef;
  border: 2px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  z-index: 10;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  top: 110%;
  background-color: #555 !important;
  color: #fff;
  padding: 5px;
  left: 100px;
  white-space: normal !important;
  font-size: 14px;
  border-radius: 5px;
  opacity: 0;
  z-index: 10;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.tooltip-container-1 {
  position: relative;
  display: inline-block;
}

.tooltip-1 {
  visibility: hidden;
  position: absolute;
  top: 110%;
  background-color: #555 !important;
  color: #fff;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  opacity: 0;
  z-index: 10;
  transition: opacity 0.3s;
}

.tooltip-container-1:hover .tooltip-1 {
  visibility: visible;
  opacity: 1;
}

.waterfall-dropdown .container button {
  background: #8cc63e;
  color: #fff;
  padding: 6px 25px;
  border-radius: 15px;
  font-weight: 500;
  border: none;
}
.tooltip-container-2 {
  position: relative;
  display: inline-block;
}

.tooltip-text-2 {
  visibility: hidden;
  min-width: 350px;
  background-color: #555;
  color: #fff;
  font-size: 14px;
  text-align: left;
  border-radius: 6px;
  padding: 14px 14px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container-2:hover .tooltip-text-2 {
  visibility: visible;
  opacity: 1;
}
.navbar-light .navbar-toggler {
  border: none !important;
  box-shadow: none;
  padding: 0px;
}
.custom-toggler.collapsed .navbar-toggler-icon-1 i {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.custom-toggler .navbar-toggler-icon-1 i {
  transition: transform 0.3s ease;
}

.custom-toggler.collapsed .navbar-toggler-icon-1 i.fa-x {
  transform: rotate(180deg);
}

.custom-toggler .navbar-toggler-icon-1 i.fa-bars {
  transform: rotate(0deg);
}
.navbar-collapse button {
  width: 140px;
}
.PricingSection2 .card-item .Main-btn {
  width: 200.575px;
  height: 42.938px;
}
.imgContainer .Timeline {
  min-height: 180px;
}
span.origin-url.py-0.ps-4.tooltip-container {
  /* width: 270px; */
  text-overflow: ellipsis;
  /* overflow: hidden; */
}
.Structure .col-md-7 button.active {
  background: #8cc63e;
  color: #fff;
  border: 2px solid #8cc63e;
  box-shadow: 5px 4px 7px 0px color(display-p3 0 0 0 / 0.25);
  transition: all 0.8s;
}
@media screen and (min-width: 300px) and (max-width: 768px) {
  .HeroSection1 .last-div .flex-wrap .last-img-div {
    display: none;
  }
  .ToolsSection1 .searchBoxHeading {
    padding: 0px !important;
    align-items: center;
  }
  .ToolsSection1 .searchBoxHeading span {
    font-size: 14px !important;
  }

  .tooltip-container-2 {
    position: fixed;
  }
  .Structure .padding,
  .Structure .padding .about-list .borderLine h4 {
    padding: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .Structure .padding .about-list i {
    padding: 0px 10px;
  }
  .PricingSection3 {
    margin-top: 100px !important;
  }
  .Structure .padding .mb-5 {
    margin: 20px 0px !important;
    font-size: 19px !important;
  }
  .PricingSection2 {
    gap: 31px;
  }
  .SummarySection .summaryfirst .image,
  .title {
    min-height: 220px !important;
  }

  .Structure .tab {
    justify-content: flex-start !important;
  }
  .tab {
    justify-content: flex-start !important;
  }
  .Performance .boxSection {
    justify-content: center !important;
  }
  /* .btn-div button {
    margin-bottom: 20px;
  } */
  .boxSection .mx-1 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .Timeline {
    height: 142px;
  }
  .Performance .coler1 {
    margin: 0px !important;
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .Performance .coler2 {
    margin: 0px !important;
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .Performance .meter-box .box1.mx-5 {
    margin: 0px !important;
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .footer-list ul li .col-md-12 {
    text-align: center !important;
  }
  .list-group-item.icon .m-left {
    justify-content: center I !important;
  }
  .BlogSection2 .icon-div {
    padding: 0px;
  }
  .BlogSection2 h2 {
    line-height: 30.99px;
  }
  .BlogSection2 .paragraph {
    height: auto;
    padding: 30px 0px;
  }
  .footer .text-start img {
    text-align: center;
    display: flex;
    align-self: center;
  }
  .HeroSection1 .p-tag {
    font-size: 18px;
  }
  .HeroSection1 {
    min-height: 765px;
  }
  .HeroSection1 .p-tag-1 {
    font-size: 14px;
    line-height: 24.09px;
  }
  .ToolsSection2 .box {
    width: 100%;
  }
  .SummarySection .summaryfirst {
    gap: 20px;
  }
  .SummarySection .title {
    padding: 0px 10px !important;
  }
  .ToolsSection2 button.box-demo {
    width: 80% !important;
  }
  /* .Waterfall .heading {
    gap: 40px;
  } */
  .waterfall-dropdown .dropdown-content {
    overflow: auto;
  }
  .paddingSpace1 {
    padding: 0px !important;
  }
  .paddingSpaceBottom {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .Waterfall .heading .wrap {
    white-space: nowrap;
  }
  .waterfall-dropdown.container.px-4.m-0 {
    width: 300%;
  }
  .WaterfallTitle .container.table:hover {
    background-color: white;
  }
  .Waterfall .tableBottom {
    background: #fff;
    border: none;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .Waterfall .tableBottom div {
    padding: 1rem !important;
    padding-right: 0px !important;
  }
  .Waterfall .heading div {
    background-color: #efefef;
    border-left: 2px solid #efefef;
    border-right: 2px solid #efefef;
  }
  .Waterfall .heading div:nth-child(2) {
    text-align: center;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .paddingSpace {
    padding: 0px !important;
  }

  .Waterfall .tableHeading .border-bottom-2 {
    background-color: #efefef;
  }
  .Waterfall .tableData .border-bottom-2 div {
    border-bottom: 2px solid #efefef;
    min-height: 40px;
    display: flex;
    align-items: center;
    border-top: 2px solid #efefef;
  }
  .Waterfall .tableData .table {
    padding: 0px !important;
  }
  .Waterfall .table .urlPath .origin-url {
    /* display: inline; */
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Waterfall .heading .col-md-3.ps-4 {
    width: 230px;
  }
}
/* @media screen and (min-width: 768px) and (max-width: 1024px) { */
/* .Waterfall .border-bottom-2{
    border: 0px !important;
    padding: 0px !important;
  }
  .Waterfall .tableHeading {
    padding: 0px !important;
  }
  .Waterfall .tableHeading .col-12 {
    background-color: #efefef;
  }
  .Waterfall .tableHeading .row {
    flex-wrap: nowrap;
  }
  .Waterfall .tableHeading .col-md-3 {
    width: 45% !important;
    padding: 1rem 0rem !important;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
  }
  .Waterfall .tableHeading .col-md-1 {
    width: 16% !important;
    padding: 1rem 0rem !important;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
  }
  .Waterfall .tableHeading .col-md-2 {
    width: 25% !important;
    padding: 1rem 0rem !important;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
  }
  .Waterfall .tableHeading .urlHeading {
    width: 30% !important;
    padding-left: 35px !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
  }
  .Waterfall .tableData .col-md-3 {
    width: 45% !important;
    height: 53px;
    padding: 10px 0px !important;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
  }
  .Waterfall .tableData .urlHeading {
    width: 30% !important;
    height: 53px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 10px 0px !important;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
  }
  .Waterfall .tableData .col-md-1 {
    width: 16% !important;
    height: 53px;
    padding: 10px 0px !important;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
  }
  .Waterfall .tableData .col-md-2 {
    width: 25% !important;
    height: 53px;
    padding: 10px 0px !important;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
  }
} */
@media screen and (min-width: 768px) and (max-width: 992px) {
  /* .Performance .col-md-12 {
    justify-content: space-around !important;
  } */
  .Performance .col-md-12 .col-md-3 {
    height: 158px;
    width: 312px !important;
  }
  .Performance .col-md-12 .space {
    margin-top: 3rem !important;
  }
  .PricingSection2 {
    justify-content: center !important;
    gap: 31px;
  }
  .BlogSection2 .icon-div {
    padding: 0px;
  }
  .BlogSection2 .paragraph {
    height: auto;
    padding: 30px 0px;
  }
  .SummarySection .summaryfirst {
    flex-direction: column;
    gap: 20px;
  }
  .SummarySection .summaryfirst .image,
  .title {
    width: 100% !important;
  }

  .Timeline {
    height: 142px;
  }
  .Performance .coler2 {
    margin: 0px !important;
  }
  .Performance .coler1 {
    margin: 0px !important;
  }
  .Performance .box1.mx-5 {
    margin: 0px !important;
    justify-content: space-evenly;
  }
  .PricingSection2 .card-item {
    width: 332.12px;
  }
  .Performance .space1 {
    justify-content: space-around;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .Performance .coler1 {
    margin: 0px !important;
  }
  .PricingSection2 .card-item {
    width: 332.12px;
  }
  /* .navbar-nav .nav-item a{} */
  .PricingSection2 {
    justify-content: center !important;
    gap: 31px;
  }
  .Performance .coler2 {
    margin: 0px !important;
  }
  .Performance .box1.mx-5 {
    margin: 0px !important;
  }
  .Timeline {
    height: 142px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .HeroSection1 .p-tag {
    line-height: 25px;
  }
  .PricingSection2 .card-item .Main-btn {
    width: 200.575px;
    height: 42.938px;
  }
  .PricingSection2 .col-md-12.position-absolute.text-center.bottom-0.mb-5 {
    width: 100%;
    margin-bottom: 30px !important;
  }
  .Timeline {
    overflow: auto;
    height: 142px;
  }
  .meter-box .space1,
  .boxSection {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1440px) and (max-width: 2560px) {
  .Timeline {
    overflow: auto;
    height: 142px;
    justify-content: center !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .Timeline {
    overflow: auto;
    height: 142px;
  }
  .PricingSection2 .card-item {
    width: 332.12px;
  }
  .PricingSection2 .col-md-12.position-absolute.text-center.bottom-0.mb-5 {
    width: 100%;
    margin-bottom: 30px !important;
  }
  .PricingSection2 .card-item .Main-btn {
    width: 200.575px;
    height: 42.938px;
  }
}
@media screen and (min-width: 1439px) and (max-width: 2560px) {
  .PricingSection2 .col-md-12.position-absolute.text-center.bottom-0.mb-5 {
    width: 100%;
    margin-bottom: 30px !important;
  }
  .PricingSection2 .card-item .Main-btn {
    width: 200.575px;
    height: 42.938px;
  }
  .PricingSection2 .card-item {
    width: 390.12px;
  }
}
@media screen and (min-width: 300px) and (max-width: 1024px) {
  .HeroSection1 .img {
    max-width: 70%;
    width: 80%;
    height: 90%;
    max-height: 100%;
  }
  .WaterfallTitle {
    overflow-x: auto;
  }

  .meter-box .space1,
  .boxSection {
    justify-content: center !important;
  }

  .Performance .lightblue {
    flex-wrap: wrap !important;
  }
  .meter-box .space1,
  .boxSection {
    justify-content: space-evenly !important;
  }
  .meter-box .space1:last-child {
    margin-top: 0px !important;
  }
  .Timeline {
    overflow: auto;
    white-space: nowrap;
  }
  .HeroSection1 .img1 {
    max-width: 70%;
    width: 80%;
    margin-top: 0px;
    height: 90%;
    max-height: 100%;
  }
  .main .d-flex.justify-content-end.col-md-3 {
    margin-top: -35px;
  }
  .HeroSection1 .input-div {
    margin-top: -40px;
  }
  .HeroSection1 .heading h2 {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  .heading.d-flex.flex-column.justify-content-end.px-2.col-md-6 {
    margin-top: -60px;
  }
  .PricingSection3 .padding {
    padding: 0px;
  }
  .PricingSection3 .about-list {
    padding: 30px 20px;
    border-radius: 10px;
  }
  .PricingSection3 .about-list h4 {
    font-size: 20px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .footer .text {
    text-align: center;
    font-size: 30px;
  }
  .footer .text-start p {
    text-align: center;
    margin-bottom: 5px;
  }
  .list-group-item .col-md-8 {
    text-align: center !important;
  }
  .list-group-item + .list-group-item {
    display: flex !important;
    justify-content: center !important;
  }
  .HeroSection2 .font {
    font-size: 30px;
    text-align: center !important;
  }
  .HeroSection2 .first-child {
    margin-top: 40px;
  }
  .HeroSection1 .input-div {
    margin-bottom: 30px;
  }
  .HeroSection2 .content-div {
    margin-top: 50px;
    padding: 0px !important;
  }
  .HeroSection1 .p-tag {
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
    line-height: 20.09px;
  }
  .HeroSection1 .last-div {
    padding: 20px 0px;
    height: auto;
  }
  .footer {
    margin-top: 50px;
  }
  .btn-div .margin {
    margin: 0px !important;
  }
  /* .PricingSection2 .card-item {
    width: 280.12px;
  } */
  /* .PricingSection2 .card-item {
    margin: 20px 0px 20px 0px !important;
  } */
  .PricingSection2 .card-item .container.mt-5.ps-5.col-md-8.pe-0 h2 {
    padding: 0px !important;
    font-size: 32px;
  }
  .PricingSection2 .card-item .container.mt-5.ps-5.col-md-8.pe-0 p {
    padding: 0px !important;
  }
  .PricingSection2 .col-md-12.position-absolute.text-center.bottom-0.mb-5 {
    width: 100%;
    margin-bottom: 30px !important;
  }
  .PricingSection2 .card-item .Main-btn {
    width: 200.575px;
    height: 42.938px;
  }
  .HeroSection1 .heading p {
    margin: 0px !important;
    text-align: center;
    padding-top: 0px !important;
    font-size: 14px !important;
  }
}
